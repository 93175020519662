import { useQuery, useMutation } from "@tanstack/react-query";
import { UserApps } from "../client/http-reqs/user-apps";
import { TApps } from "@/types";

export function useAppsList() {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useAppsList"],
    queryFn: async () => {
      return await UserApps.getAppsList();
    },
  });

  return { data, isLoading, error, status, isSuccess, refetch };
}

export function useUserApps(id: string) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useUserApps"],
    queryFn: async () => {
      return await UserApps.getUserApps(id);
    },
  });

  return { data, isLoading, error, status, isSuccess, refetch };
}

export function useAppDetails(id: string) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useAppDetails"],
    queryFn: async () => {
      return await UserApps.getAppDetails(id);
    },
    enabled: !!id,
    retry: 1,
  });

  return { data, isLoading, error, status, isSuccess, refetch };
}

export function useDeveloperApps(devId: string) {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery({
    queryKey: ["useDeveloperApps"],
    queryFn: async () => {
      return await UserApps.getDeveloperApps(devId);
    },
    enabled: !!devId,
    retry: 1,
  });

  return { data, isLoading, error, status, isSuccess, refetch };
}

export function useAddApp() {
  const { mutate, error, status, isSuccess, isPending } = useMutation({
    mutationKey: ["useAddApp"],
    mutationFn: async (
      body: {
        appID: string;
        userId: string;
      }[]
    ) => {
      return await UserApps.addApp(body);
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  return { mutate, isPending, error, status, isSuccess };
}

export function useAppReviews(appId: string) {
  const {
    data,
    isLoading,
    error,
    status,
    isSuccess,
    refetch,
    isPending,
    isFetching,
  } = useQuery({
    queryKey: ["useAppReviews"],
    queryFn: async () => {
      return await UserApps.getAppReviews(appId);
    },
    enabled: !!appId,
    refetchIntervalInBackground: true,
    refetchInterval: 10000,
  });

  return {
    data,
    isLoading,
    error,
    status,
    isSuccess,
    refetch,
    isPending,
    isFetching,
  };
}

export function useGenerateReplyToReview() {
  const { mutate, error, status, isSuccess, isPending, data } = useMutation({
    mutationKey: ["useGenerateApp"],
    mutationFn: async (body: { appId: string; reviewId: string }) => {
      return await UserApps.generateReply(body);
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  return { mutate, isPending, error, status, isSuccess, data };
}

export function useReplyToReview() {
  const { mutate, error, status, isSuccess, isPending } = useMutation({
    mutationKey: ["useReplyToReview"],
    mutationFn: async (body: {
      appId: string;
      replyText: string;
      reviewId: string;
    }) => {
      return await UserApps.replyToReview(body);
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  return { mutate, isPending, error, status, isSuccess };
}

export function useTranslateText() {
  const { mutate, error, status, isSuccess, isPending, data } = useMutation({
    mutationKey: ["useTranslateText"],
    mutationFn: async ({
      language,
      text,
    }: {
      text: string;
      language: string;
    }) => {
      return await UserApps.translateText(text, language);
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  return { mutate, isPending, error, status, isSuccess, data };
}
